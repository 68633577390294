<!--设置管理布局-->
<template>
  <div id="setLayout">
    <div class="page">
      <div class="header">
        <img class="image" src="../../../public/images/sidebar/alarm_normal.png" />
        <span>{{$t('notificationEquipment.a2')}}</span>
      </div>
      <div class="main">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
#setLayout {
  width: 100%;
  height: 100%;
  padding: 10px 10px 10px 20px;
}
.page{
  width: 100%;
  height: 100%;
  border-radius: 3px;
  box-shadow: 0px 0px 10px #bdbcbc;
  background-color: #ffffff;
  overflow: hidden;
}
.header {
  height: 60px;
  padding: 10px 20px 0 20px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
}
.image{
  width: 38px;
}
.header span {
  margin-left: 20px;
  font-size: 20px;
}
.main {
  width: 100%;
  height: calc(100% - 60px);
  /* padding: 20px; */
  /* overflow: auto; */
}
</style>